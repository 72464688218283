import type ReactDOM from 'react-dom/client';
import type { RunClientAppOptions } from '@ice/runtime';
import type { PageConfig, PageConfigDefinition } from './types';

// Only used for type defination of pageConfig.
export function definePageConfig(pageConfig: PageConfig | PageConfigDefinition): PageConfigDefinition {
  if (typeof pageConfig !== 'function') {
    return () => pageConfig;
  } else {
    return pageConfig;
  }
}

// Only used for type defination of runApp.
type RunApp = (render: (customOptions?: Partial<RunClientAppOptions>) => Promise<ReactDOM.Root>, options?: RunClientAppOptions) => void;
export function defineRunApp(runApp: RunApp): RunApp {
  return runApp;
}
